@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700;800;900&display=swap");

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
}

.app {
  display: flex;
  position: relative;
}

/* Adjust scrollbar width for smaller screens */
::-webkit-scrollbar {
  width: 5px;
}

/* Adjust scrollbar track and handle styles */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Media queries for responsiveness */

/* Mobile Phones */
@media only screen and (max-width: 480px) {
  .app {
    flex-direction: column; /* Stack items vertically on smaller screens */
  }

  /* Adjust font sizes for smaller screens */
  body {
    font-size: 14px; /* Example font size adjustment */
  }
}

/* Mobile Phones in Landscape */
@media only screen and (min-width: 481px) and (max-width: 767px) {
  .app {
    flex-direction: column; /* Stack items vertically on smaller screens */
  }

  /* Adjust font sizes for smaller screens */
  body {
    font-size: 16px; /* Example font size adjustment */
  }
}

/* Tablets Portrait */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .app {
    flex-direction: column; /* Stack items vertically on smaller screens */
  }

  /* Adjust font sizes for smaller screens */
  body {
    font-size: 18px; /* Example font size adjustment */
  }
}

/* Tablets Landscape and Desktops */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .app {
    flex-direction: row; /* Arrange items horizontally on larger screens */
  }
}

/* Large Desktops */
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .app {
    flex-direction: row; /* Arrange items horizontally on larger screens */
  }
}

/* Extra Large Desktops */
@media only screen and (min-width: 1400px) {
  .app {
    flex-direction: row; /* Arrange items horizontally on larger screens */
  }
}
